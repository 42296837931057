<template>
	<div class="info__container" @mouseleave="hideOverlay">
		<div
			v-if="page"
			:class="'info__overlay-event-left ' + activeOverlayLeft"
			@mouseenter="showOverlay('right')"
		></div>
		<div
			v-if="page"
			:class="'info__overlay-bg-left ' + activeOverlayLeft"
		></div>
		<div
			v-if="page"
			:class="'info__overlay-color-left ' + activeOverlayLeft"
		></div>
		<div
			v-if="page"
			:class="'info__overlay-event-right ' + activeOverlayRight"
			@mouseenter="showOverlay('left')"
		></div>
		<div
			v-if="page"
			:class="'info__overlay-bg-right ' + activeOverlayRight"
		></div>
		<div
			v-if="page"
			:class="'info__overlay-color-right ' + activeOverlayRight"
		></div>

		<ul class="info" v-if="data">
      <!-- limit on data for build prerender else building duplicates -->
			<li
				class="info__item"
				:class="item.class + ' ' + (page ? 'info__main-item' : '')"
				v-for="(item, index) in data.slice(0, 50)"
				:key="index"
			>
				<h3 v-if="!page" class="main__title">{{ item.title }}</h3>
				<div class="info__box">
					<h3 v-if="page" class="main__title">{{ item.title }}</h3>
					<div
						class="info__image"
						v-if="item.image"
						:style="{ backgroundImage: `url(${item.image})` }"
					></div>

					<div class="info__content">
						<h4 class="info__sub-title" v-if="item.subtitle">
							{{ item.subtitle }}
						</h4>

						<div class="info__text editor" v-html="item.content"></div>

						<div
							class="info__button-box"
							v-if="item.url"
							:class="{ 'flex--center': item.image }"
						>
							<template
								v-if="item.url.match(/https:/) || item.url.match(/http:/)"
							>
								<a :href="item.url" target="_blank" class="button">
									{{ item.button_title }}
								</a>
							</template>
							<template v-else>
								<router-link
									@mouseleave="hideOverlay"
									:to="item.url"
									class="button"
									:class="
										item.alias == 'holder_1_left'
											? 'btn_' + activeOverlayLeft
											: 'btn_' + activeOverlayRight
									"
								>
									{{ item.button_title || 'Узнать подробнее' }}
								</router-link>
							</template>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Info',
	data() {
		return {
			activeOverlayLeft: '',
			activeOverlayRight: '',
			page: true,
		}
	},
	props: {
		data: {
			type: Array,
			required: true,
	},
	},
	created() {
		this.page = this.$route.fullPath.replace(/\//gi, '') == 'holder'
	},
	methods: {
		showOverlay(alias) {
			if (this.data.length > 0) {
				if (
					this.data[0].alias == 'holder_1_left' ||
					this.data[0].alias == 'holder_1_right'
				) {
					if (alias == 'right') {
						this.activeOverlayLeft = ''
						this.activeOverlayRight = 'active'
					} else if (alias == 'left') {
						this.activeOverlayLeft = 'active'
						this.activeOverlayRight = ''
					}
				}
			}
		},
		hideOverlay() {
			if (this.data.length > 0) {
				if (
					this.data[0].alias == 'holder_1_left' ||
					this.data[0].alias == 'holder_1_right'
				) {
					this.activeOverlayLeft = ''
					this.activeOverlayRight = ''
		}
	}
		},
	},
}
</script>
